import React from "react";


const Loading = () => (
    <div className="spinner">
        <h1>Loading</h1>
    </div>
);

export default Loading;
