import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Modal from "react-modal";
import CodeBlock from "@tenon-io/tenon-codeblock";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "./Loading";
import { Button } from "react-bootstrap";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
} from "@ionic/react";
import Card from "reactstrap/es/Card";
import CardTitle from "reactstrap/es/CardTitle";
import CardBody from "reactstrap/es/CardBody";

import { Bar } from "react-chartjs-2";
import axios from "axios";
import ErrorBoundary from "./ErrorBoundary";

var delStreamPath;
var delViewerPath;
var delStreamKey;

var testMessage = [
  {
    name: "Joe Sample",
    email: "johnsonjp34@gmail.com",
    message: "This is a message to the Pastor of Faith Tech Church",
  },
  {
    name: "Frank Sample",
    email: "johnsonjp34@gmail.com",
    message: "This is a message to the Pastor of Faith Tech Church",
  },
];

var metaDataFromAuth = [];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

var codeStr;

var sampledata = [
  { x: "2016-12-25", y: 20 },
  { x: "2016-12-26", y: 10 },
];

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function compressArray(original) {
  var compressed = [];
  // make a copy of the input array
  var copy = original.slice(0);

  // first loop goes over every element
  for (var i = 0; i < original.length; i++) {
    var myCount = 0;
    // loop over every element in the copy and see if it's the same
    for (var w = 0; w < copy.length; w++) {
      if (original[i] == copy[w]) {
        // increase amount of times duplicate is found
        myCount++;
        // sets item to undefined
        delete copy[w];
      }
    }

    if (myCount > 0) {
      var a = new Object();
      a.x = original[i];
      a.y = myCount;
      compressed.push(a);
    }
  }

  return compressed;
}

export const ProfileComponent = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [userKey, setUserKey] = React.useState("");
  const [userURL, setUserURL] = React.useState("");
  const [userCode, setUserCode] = React.useState("");
  const [userSub, setUserSub] = React.useState("");
  const [codeInfo, setCodeInfo] = React.useState("");
  const [codeWPInfo, setCodeWPInfo] = React.useState("");
  const [streamLinkInfo, setStreamLinkInfo] = React.useState("");
  const [graphInfo, setGraphInfo] = React.useState("");
  const [messageInfo, setMessageInfo] = React.useState("");
  const [videoInfo, setVideoInfo] = React.useState("");

  useEffect(() => {
    //
    getUserMetadata();
    /*
        setMessageInfo([{
            "name": "Joe Sample",
            "email": "johnsonjp34@gmail.com",
            "message": "This is a message to the Pastor of Faith Tech Church"

        }, {
            "name": "Frank Sample",
            "email": "johnsonjp34@gmail.com",
            "message": "This is a message to the Pastor of Faith Tech Church"

        }]) */
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  function deleteVideoRoute(nameOfVideo) {

    let delURLPrefix = delStreamPath.split('.com')[0]
    let delURL = delURLPrefix.split('rtmps://')[1]
    console.log(delURL)

    let delViewerPrefix = delViewerPath.split(".net/")[1]
    let playbackPath = delViewerPrefix.split("/index.m3u8")[0]

    console.log(playbackPath)


    let fullDelURL = "https://" + delURL + ".com" + "/delupload" + playbackPath
    console.log(fullDelURL)

    let truncatedStreamKey = delStreamKey.split("?")[0]
    console.log(truncatedStreamKey);

    axios
        .post(fullDelURL, {
          streamKey: truncatedStreamKey,
          videoToDelete:nameOfVideo,
          checkCode:"pizza"
        })
        .then(
            (response) => {
              console.log(response.data);
              alert("Deleting in Queue. Please check back in about 5 minutes.")
            },
            (error) => {
              console.log(error);
            }
        );


  }

  function processVideoInfo(data, streamURL, playBackURL, streamKey) {
    console.log(streamURL)
    console.log(playBackURL)
    delStreamPath = streamURL;
    delViewerPath = playBackURL;
    delStreamKey = streamKey
    let videoData;
    if(data == "directory.json"){
      videoData = data.replace("directory.json", "");
    }
    else {
      videoData = data.replace(",directory.json", "");
    }
    var array = videoData.split(",");

    console.log(array);

    let buttonFriendlyObj = [];

    for (var listi = 0; listi < array.length; listi++) {
      console.log(array[listi]);

      buttonFriendlyObj.push({ name: array[listi], filename: array[listi] });
    }

    setVideoInfo(buttonFriendlyObj);
    //this.setState({stateChanger: true})
  }

  function processMessageInfo(data) {
    //organize messages by date.

    let organizedList = [];

    try {
      //for (let r = 0; r < data.length; r++) {}

      var sortedJsObjects = data.sort(function (a, b) {
        return Math.abs(a.date - b.date);
      });

      console.log(sortedJsObjects);

      //let sortedWithCleanDate = []

      for (let r = 0; r < sortedJsObjects.length; r++) {
        let storedDate = sortedJsObjects[r].date;
        var a = new Date(storedDate);
        var months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        let moreFriendlyDate = month + "-" + date + "-" + year;

        sortedJsObjects[r].date = moreFriendlyDate;
      }

      sortedJsObjects.reverse();
      setMessageInfo(sortedJsObjects);

      // for (let r = 0; r < sortedJsObjects.length; r++) {}
    } catch (e) {
      console.log(e);
    }
  }

  function reachOutToStats(streamCodeForStats) {
    axios
      .post("https://analytics.relayfaith.com/statviewer", {
        orgID: streamCodeForStats,
      })
      .then(
        (response) => {
          console.log(response.data);

          var ArrofDates = [];

          for (var i = 0; i < response.data.length; i++) {
            var aTime = response.data[i].date;

            console.log(aTime);

            var utcSeconds = aTime / 1000;
            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            d.setUTCSeconds(utcSeconds);

            ArrofDates.push(formatDate(d));
          }

          setGraphInfo(compressArray(ArrofDates));
          //this.setState({graphData: compressArray(ArrofDates)})

          console.log(graphInfo);
          //this.setState({graphRefresh:true})

          /*
                                var utcSeconds = aTime / 1000;
                                var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
                                d.setUTCSeconds(utcSeconds);

                                console.log(d)

                                console.log(formatDate(d));
                */
        },
        (error) => {
          console.log(error);
        }
      );
  }

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  //const [userMetadata, setUserMetadata] = useState(null);
  const getUserMetadata = async () => {
    var self = this;

    const domain = "dev-t90wt7gn.auth0.com";

    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });

      // console.log(accessToken)

      const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const { user_metadata } = await metadataResponse.json();
      console.log(user_metadata);
      setUserKey(user_metadata.streamKey);
      setUserURL(user_metadata.streamURL);
      setUserCode(user_metadata.streamCode);
      setUserSub(user_metadata.subscriptionName);
      metaDataFromAuth.push(user_metadata);

      try {
        reachOutToStats(user_metadata.streamCode);
      } catch (e) {
        console.log(e);
        console.log("Couldn't get stats.");
      }
      var string1 =
        '<script src="https://embed.relayfaith.com/video.min.js"> </script>\n' +
        '<script src="http://ajax.googleapis.com/ajax/libs/jquery/1/jquery.min.js"></script>\n' +
        '<link href="https://embed.relayfaith.com/video-js.min.css" rel="stylesheet"> </link>\n' +
        '<link href="https://embed.relayfaith.com/videoplayer.css" rel="stylesheet"> </link>\n';

      var string2 =
        "<script>\n " +
        "var baseVODURL = " +
        '"' +
        user_metadata.viewVODLink +
        '";' +
        "\n " +
        "var liveURL = " +
        '"' +
        user_metadata.viewLink +
        '";' +
        "\n " +
        "var streamAccessCode = " +
        '"' +
        user_metadata.streamCode +
        '";' +
        "\n</script>\n";

      var string3 =
        '<script src="https://embed.relayfaith.com/basicplayer.js"> </script>\n' +
        '<div id="faithplayer"></div>';

      codeStr = string1 + string2 + string3;

      setCodeInfo(codeStr);

      var string4 =
        '<div id="faithplayer"></div>' +
        "<script> " +
        "var baseVODURL = " +
        '"' +
        user_metadata.viewVODLink +
        '";' +
        "var liveURL = " +
        '"' +
        user_metadata.viewLink +
        '";' +
        "var streamAccessCode = " +
        '"' +
        user_metadata.streamCode +
        '"' +
        " </script>" +
        ' <script src="https://embed.relayfaith.com/consolidatedstream.js"> </script> ';

      setCodeWPInfo(string4);

      var string5 =
        "https://webplayer.relayfaith.com?streamcode=" +
        user_metadata.streamCode;
      setStreamLinkInfo(string5);

      // console.log(accessToken)

      let raw = JSON.stringify({ orgID: user_metadata.streamCode });


      const videoInfo = await fetch(
          user_metadata.viewVODLink + "/directory.json",
          {
            method: "GET",
            mode: "cors",
          }
      )
          .then((response) => response.text())
          .then((data) => {
            processVideoInfo(
                data,
                user_metadata.streamURL,
                user_metadata.viewLink,
                user_metadata.streamKey
            );
          });

      const messagingInfo = await fetch(
        "https://messaging.relayfaith.com/authorized",
        {
          method: "POST",
          mode: "cors",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: raw,
        }
      )
        .then((response) => response.json())
        .then((data) => {
          processMessageInfo(data);
        });


      //  console.log(messagingInfo);
    } catch (e) {
      console.log(e.message);
    }
  };

  const data = {
    datasets: [
      {
        label: "Views Per Day",
        data: graphInfo,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const options = {
    title: {
      display: true,
      text: "Viewer Stats",
    },
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            unit: "day",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            precision: 0,
            suggestedMin: 0,
          },
        },
      ],
    },
  };

  var MessageItems = () => <div></div>;

  try {
    MessageItems = messageInfo.map((messages) => (
      // Only do this if items have no stable IDs

      <IonCardContent key={messages.index}>
        {messages.name}
        <br />
        {messages.email}
        <br />
        {messages.date}
        <br />
        {messages.message}
      </IonCardContent>
    ));
  } catch (e) {
    console.log(e);
  }

  var VideoItems = () => <div></div>;

  try {
    VideoItems = videoInfo.map((videos) => (
      // Only do this if items have no stable IDs

        <IonCardContent key={videos.index}><Button onClick={() => deleteVideoRoute(videos.name)}>{videos.name}</Button></IonCardContent>
    ));
  } catch (e) {
    console.log(e);
  }

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}></Col>
        <Col md>
          <p className="lead text-muted">{user.email}</p>
        </Col>
      </Row>
      <Row>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle> Your Stream Url is:</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                <br />
                {userURL}
              </IonCardContent>
            </IonCard>

            <IonCard>
              <IonCardHeader>
                <IonCardTitle> Your Stream Key is:</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                <br />
                {userKey}
              </IonCardContent>
            </IonCard>
          </div>
        </Modal>
      </Row>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle> Subscription:</IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
          <br />
          {userSub}
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            {" "}
            Your stream code for users to access your stream from our apps is:
          </IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
          <br />
          {userCode}
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            {" "}
            Your link to take viewers directly to your streaming page:
          </IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
          <br />
          <CodeBlock codeString={streamLinkInfo} />
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            {" "}
            Click Show Keys for your stream key and the server URL. These are
            not to be shared.
          </IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
          <br />
          <Button onClick={() => openModal()}>Show Keys</Button>
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle> Analytics</IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
          <Bar data={data} options={options} />
          These stats show viewers that have watched for at least 10 minutes of
          a production.
        </IonCardContent>
      </IonCard>

      <IonCard style={{ display: "flex", flexDirection: "column" }}>
        <IonCardHeader>
          <IonCardTitle> Messages & Check Ins</IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
          Entries with missing information are users that have decided not to
          input their information.
        </IonCardContent>

        {/* <ErrorBoundary> <MessageItems/> </ErrorBoundary> */}
        <IonCardContent
          style={{ maxHeight: "250px", overflow: "scroll", overflowY: "auto" }}
        >
          <ErrorBoundary>{MessageItems}</ErrorBoundary>
        </IonCardContent>
      </IonCard>

      <IonCard style={{ display: "flex", flexDirection: "column" }}>
        <IonCardHeader>
          <IonCardTitle> Delete Videos</IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
         (beta) If you would like to remove a video please select it from below. Please allow about 5-10 minutes for the video to be removed from the archives.
        </IonCardContent>

        {/* <ErrorBoundary> <MessageItems/> </ErrorBoundary> */}
        <IonCardContent
          style={{ maxHeight: "250px", overflow: "scroll", overflowY: "auto" }}
        >
          <ErrorBoundary>{VideoItems}</ErrorBoundary>
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle> Code for Embedding into Your Site</IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
          For sites friendly to Javascript code snippets:
          <br />
          <CodeBlock codeString={codeInfo} />
        </IonCardContent>

        <IonCardContent>
          For Word Press based sites with code editors that tend to inject tags:
          <br />
          <CodeBlock codeString={codeWPInfo} />
        </IonCardContent>
      </IonCard>
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
