import React, {Component} from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonItem, IonIcon, IonLabel, IonButton } from '@ionic/react';
import styled from "@emotion/styled";
import './Tab1.css';

import Login from "../components/Login";
import {Button, Container} from "reactstrap";
import { StitchAuthProvider, useStitchAuth } from "../components/StitchAuth";
import {Bar} from "react-chartjs-2";
import axios from 'axios';

import ErrorBoundary from "../components/ErrorBoundary";


var testMessage = []



//

const MessageItems = testMessage.map( (messages) =>
    // Only do this if items have no stable IDs

    (<IonCardContent key={messages.index}>
        {messages.name}
        <br/>
        {messages.email}
        <br/>
        {messages.date}
        <br/>
        {messages.message}
    </IonCardContent>)
);




const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  * {
    font-family: sans-serif;
  }
`;
const Navbar = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  height: 62px;
  padding: 10px;
  background: #D3D3D3;
`;
const AppTitle = styled.h1`
  margin-right: auto;
`;


function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

function compressArray(original) {

    var compressed = [];
    // make a copy of the input array
    var copy = original.slice(0);

    // first loop goes over every element
    for (var i = 0; i < original.length; i++) {

        var myCount = 0;
        // loop over every element in the copy and see if it's the same
        for (var w = 0; w < copy.length; w++) {
            if (original[i] == copy[w]) {
                // increase amount of times duplicate is found
                myCount++;
                // sets item to undefined
                delete copy[w];
            }
        }

        if (myCount > 0) {
            var a = new Object();
            a.x = original[i];
            a.y = myCount;
            compressed.push(a);
        }
    }

    return compressed;
};

class Landing extends Component  {



    constructor(props) {
        super(props);
        this.state = {
            stateChanger: "",
            selectedFile: null,
            loaded:0,
            graphRefresh:false,
            graphData: []

        }
    }






    componentDidMount() {

       // this.reachOutToStats();

    }

    render() {
        const data = {

            datasets: [
                {
                    label: "Views Per Day",
                    data: this.state.graphData,
                    fill: true,
                    backgroundColor: "rgba(75,192,192,0.2)",
                    borderColor: "rgba(75,192,192,1)"
                }
            ]
        };


        const options = {
            title: {
                display: true,
                text: "Viewer Stats"
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    time: {
                        unit: 'day'
                    }
                }],
                yAxes: [
                    {
                        ticks: {
                            suggestedMin: 0,
                            suggestedMax: 10
                        }
                    }
                ]
            }
        };


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Relay Faith</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Relay Faith</IonTitle>


          </IonToolbar>
        </IonHeader>

          <IonCard>

              {/*<img style={{display: "block", marginLeft:"auto", marginRight:"auto"}} src="../assets/IMG_0207.PNG"/>*/}
          </IonCard>

          <IonCard>
              <IonCardHeader>

                  <IonCardTitle>Affordable Live Streaming for Churches</IonCardTitle>
              </IonCardHeader>


              <IonCardContent>

                <br/>
                 Use this console to find your stream configuration details.

              </IonCardContent>



          </IonCard>

          <IonCard>
              <IonCardHeader>

                  <IonCardTitle>Frequently Asked Questions Version 1.3</IonCardTitle>
              </IonCardHeader>


              <IonCardContent>
<b>
What do our viewers need to access the stream on your apps?
</b>
                  <br/>

                  You will need your stream code. This can be found by accessing the console tab below. This code can be
                  shared with anyone that you want to watch your stream.

                  <br/>
                  <br/>
<b>
                  What do I need to do to keep my stream secure for hackers or hijackers?
</b>
                  <br/> Your stream key found in the console (not the stream code) that you use in your video production software or media processor should not be shared with anyone unless you want them broadcasting on your stream.

              </IonCardContent>



          </IonCard>






      </IonContent>
    </IonPage>
  );
};
}

export default Landing;
