import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "@auth0/auth0-react";

/*
<Auth0Provider
    domain="dev-t90wt7gn.auth0.com"
    clientId="iITW3yL0dhx3Js123VC0GCenZ6cOzMIi"
    redirectUri={"https://console.relayfaith.com/console"}
    audience="https://console.relayfaith.com"
    scope="read:current_user update:current_user_metadata"
>
    <App />
    </Auth0Provider>
 */


ReactDOM.render(<Auth0Provider
    domain="dev-t90wt7gn.auth0.com"
    clientId="iITW3yL0dhx3Js123VC0GCenZ6cOzMIi"
    redirectUri={"https://console.relayfaith.com/console"}
    audience="https://dev-t90wt7gn.auth0.com/api/v2/"
    scope="read:current_user update:current_user_metadata"
>
    <App />
    </Auth0Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
