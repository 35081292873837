import React, {Component} from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import styled from "@emotion/styled";
import './Tab1.css';
// @ts-ignore

import {Button, Container} from "reactstrap";


import { useAuth0 } from "@auth0/auth0-react";
import './AuthTest'
import ExternalApi from "./AuthTest";
import Profile from "../components/Profile";
import CodeBlock from "@tenon-io/tenon-codeblock";



//



const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  * {
    font-family: sans-serif;
  }
`;
const Navbar = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  height: 62px;
  padding: 10px;
  background: #D3D3D3;
`;
const AppTitle = styled.h1`
  margin-right: auto;
`;



class Console extends Component  {



    constructor(props) {
        super(props);
        this.state = {
            stateChanger: "",
            selectedFile: null,
            loaded:0,
            showModal: false,
            refresh: "bcd"

        }


    }






    render() {

/*
        const LoginButton = () => {
            const {loginWithRedirect, isAuthenticated} = useAuth0();

            return (
                !isAuthenticated && (
                    <div>
                        <Button onClick={() => loginWithRedirect()}>Log In</Button>
                    </div>)

            )

        };
*/

        const LogoutButton = () => {
            const {logout, isAuthenticated} = useAuth0();

            return (
                isAuthenticated && (
                    <div style={{position: "absolute", right:"5%", top: "8%"}}><Button onClick={() => logout({returnTo: window.location.origin})}>
                        Log Out
                    </Button>
                    </div>)
            );
        };



  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Console</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Console</IonTitle>


          </IonToolbar>
        </IonHeader>


<LogoutButton/>



            <Profile/>



      </IonContent>
    </IonPage>
  );
};
}

export default Console;


